// const dayjs = require("dayjs-timezone")
const dayjs = require("dayjs")
var timezone = require('dayjs/plugin/timezone')
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
dayjs.extend(timezone)

export const converArrayToObject = (array, key) => {
    const initialValue = {}
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item
        }
    }, initialValue)
}

export const convertRangeToString = (from, to) => {
    if (from === dayjs().format('YYYY-MM-DD') && to === dayjs().format('YYYY-MM-DD')) {
        return "today"
    }
    if (from === dayjs().add(-1, 'day').format('YYYY-MM-DD') && to === dayjs().add(-1, 'day').format('YYYY-MM-DD')) {
        return "yesterday"
    }
    if (from === dayjs().startOf('week').format('YYYY-MM-DD') && to === dayjs().endOf('week').format('YYYY-MM-DD')) {
        return "this-week"
    }
    if (from === dayjs().add(-1, 'week').startOf('week').format('YYYY-MM-DD') && to === dayjs().add(-1, 'week').endOf('week').format('YYYY-MM-DD')) {
        return "last-week"
    }
    if (from === dayjs().startOf('month').format('YYYY-MM-DD') && to === dayjs().format('YYYY-MM-DD')) {
        return "this-month"
    }
    if (from === dayjs().startOf('month').format('YYYY-MM-DD') && to === dayjs().endOf('month').format('YYYY-MM-DD')) {
        return "this-month-all"
    }
    if (from === dayjs().add(1, 'month').startOf('month').format('YYYY-MM-DD') && to === dayjs().add(1, 'month').endOf('month').format('YYYY-MM-DD')) {
        return "next-month"
    }
    if (from === dayjs().add(-1, 'month').startOf('month').format('YYYY-MM-DD') && to === dayjs().add(-1, 'month').endOf('month').format('YYYY-MM-DD')) {
        return "last-month"
    }
    return "range"
}

export const getDateRange = (value) => {
    let from = dayjs()
    let to = dayjs()
    switch (value) {
        case "yesterday":
            from = dayjs().subtract(1, "days").format("YYYY-MM-DD");
            to = from;
            break;
        case "this-week":
            from = dayjs().startOf("week").format("YYYY-MM-DD");
            to = dayjs().endOf("week").format("YYYY-MM-DD");
            break;
        case "next-week":
            from = dayjs()
                .add(1, 'weeks')
                .startOf("week")
                .format("YYYY-MM-DD");
            to = dayjs()
                .add(1, 'weeks')
                .endOf("week")
                .format("YYYY-MM-DD");
            break;
        case "last-week":
            from = dayjs()
                .subtract(1, "weeks")
                .startOf("week")
                .format("YYYY-MM-DD");
            to = dayjs()
                .subtract(1, "weeks")
                .endOf("week")
                .format("YYYY-MM-DD");
            break;
        case "this-month":
            from = dayjs().startOf("month").format("YYYY-MM-DD");
            to = dayjs().format("YYYY-MM-DD")
            break;
        case "this-month-all":
            from = dayjs().startOf("month").format("YYYY-MM-DD");
            to = dayjs().endOf("month").format("YYYY-MM-DD")
            break;
        case "next-month":
            from = dayjs()
                .add(1, "months")
                .startOf("month")
                .format("YYYY-MM-DD");
            to = dayjs()
                .add(1, "months")
                .endOf("month")
                .format("YYYY-MM-DD");
            break;
        case "last-month":
            from = dayjs()
                .subtract(1, "months")
                .startOf("month")
                .format("YYYY-MM-DD");
            to = dayjs()
                .subtract(1, "months")
                .endOf("month")
                .format("YYYY-MM-DD");
            break;
        case "month":
            from = dayjs().startOf("month").format("YYYY-MM-DD");
            if (dayjs(from).endOf("month").isSameOrAfter(dayjs())) {
                to = dayjs().format("YYYY-MM-DD");
            } else {
                to = dayjs(from).endOf("month");
            }
            break;
        default:
            from = dayjs().format("YYYY-MM-DD");
            to = dayjs().format("YYYY-MM-DD");
            break;
    }
    return { from, to }
}


export const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60 > 0 ? mins % 60 + "min" : ""
    return h > 0 ? `${h}h ${m}` : `${m}`

}

export const COLOR_ARRAY = [
    "#FF9DBA",
    "#E2A6E6",
    "#BBC1E8",
    "#A5DFF9",
    "#91E3EE",
    "#6BD5CB",
    "#A5E5BD",
    "#E7F286",
    "#FFEC78",
    "#FFBF69",
    "#ff9a9e",
    "#fad0c4",
    "#ff7eb3",
    "#a18cd1",
    "#f6d365",
    "#fbc2eb",
    "#a1c4fd",
    "#c2e9fb",
    "#d4fc79",
    "#84fab0",
    "#8fd3f4",
    "#4facfe",
    "#8ddad5",
    "#00f2fe",
    "#b490ca",
    "#96fbc4",
    "#f6f3ff",
    "#72afd3",
    "#feada6",
    "#6f86d6",
    "#e7f0fd",
    "#deecdd",
    "#74ebd5",
    "#9face6",
    "#fbc8d4",
    "#6991c7",
    "#9795f0"
]

export const formatRangeDate = (from, to, timeZone) => {
    const startTime = "00:00"
    const endTime = "23:59:59"
    let fromWithHour = null
    let toWithHour = null
    if (timeZone) {
        fromWithHour = dayjs.tz(from + " " + startTime, timeZone).format();
        toWithHour = dayjs.tz(to + " " + endTime, timeZone).format();
    } else {
        fromWithHour = new Date(from.replace(/-/g, "/") + ' ' + startTime)
        toWithHour = new Date(to.replace(/-/g, "/") + ' ' + endTime)
    }

    return { fromWithHour, toWithHour }
}

/**
 * Update time timezone and start and end time for a range
 * @param {*} from must be dayjs object
 * @param {*} to must be dayjs object
 * @returns 
 */
export const updateRangeTime = (from, to) => {
    // const startTime = "00:00"
    // const endTime = "23:59:59"
    let fromWithHour = null
    let toWithHour = null
    fromWithHour = from.startOf("day").toDate();
    toWithHour = to.endOf("day").toDate()

    return { from: fromWithHour, to: toWithHour }
}

export const hoursOfDay = () => {
    let startTime = dayjs().hour(6).minute(0)
    let endTime = dayjs().hour(24).minute(0)
    let hours = []
    while (startTime.isBefore(endTime)) {
        hours.push({
            label: startTime.format('hh:mm a'),
            value: startTime.format('HH:mm')
        })
        startTime = startTime.add(15, 'minute')
    }
    return hours
} 