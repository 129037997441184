
export const GET_APPOINTMENTS_STARTED = "GET_APPOINTMENTS_STARTED"
export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS"
export const GET_APPOINTMENTS_FAILURE = "GET_APPOINTMENTS_FAILURE"

export const GET_APPOINTMENT_DETAIL_STARTED = "GET_APPOINTMENT_DETAIL_STARTED"
export const GET_APPOINTMENT_DETAIL_SUCCESS = "GET_APPOINTMENT_DETAIL_SUCCESS"
export const GET_APPOINTMENT_DETAIL_FAILURE = "GET_APPOINTMENT_DETAIL_FAILURE"

export const CREATE_BLOCK_TIME_STARTED = "CREATE_BLOCK_TIME_STARTED"
export const CREATE_BLOCK_TIME_SUCCESS = "CREATE_BLOCK_TIME_SUCCESS"
export const CREATE_BLOCK_TIME_FAILURE = "CREATE_BLOCK_TIME_FAILURE"

export const UPDATE_BLOCK_TIME_STARTED = "UPDATE_BLOCK_TIME_STARTED"
export const UPDATE_BLOCK_TIME_SUCCESS = "UPDATE_BLOCK_TIME_SUCCESS"
export const UPDATE_BLOCK_TIME_FAILURE = "UPDATE_BLOCK_TIME_FAILURE"

export const REMOVE_BLOCK_TIME_STARTED = "REMOVE_BLOCK_TIME_STARTED"
export const REMOVE_BLOCK_TIME_SUCCESS = "REMOVE_BLOCK_TIME_SUCCESS"
export const REMOVE_BLOCK_TIME_FAILURE = "REMOVE_BLOCK_TIME_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"
