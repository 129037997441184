import React, { Component } from "react";
import { connect } from "react-redux";
import RGL, { WidthProvider } from "react-grid-layout";
import dayjs from "dayjs";
import { Row, Col, Tooltip } from "antd";
import Icon from "@ant-design/icons";
import { HeartIcon, MessageIcon } from "../Common/Icons/Icons"
import { AppointmentStatus } from "./AppointmentStatus";
import "./dragAndDropStyle.css";
// import { nationalFormatPhone } from "../../utils/FormatPhoneNumber/FormatPhoneNumber";
import decodeHtmlEntities from "../../utils/UnexcapeString/UnexcapeString";

const ReactGridLayout = WidthProvider(RGL);


const EarlySvg = () => (
    <svg
        width="1em" height="1em"
        viewBox="0 0 122.88 108.67">
        <title>history-line</title>
        <path
            stroke="green"
            strokeWidth="6"
            d="M14.52,55.12a54.53,54.53,0,0,1,8.12-29.54A52.65,52.65,0,0,1,46.75,4.79a60.05,60.05,0,0,1,11.1-3.55,52.77,52.77,0,0,1,60.35,31.2,59.34,59.34,0,0,1,3.5,11.07,52.58,52.58,0,0,1-1.31,27,53.18,53.18,0,0,1-14.66,22.87A57.62,57.62,0,0,1,83,106.64a48.71,48.71,0,0,1-25,.74,47.51,47.51,0,0,1-7.89-2.58,49.43,49.43,0,0,1-7.56-4,3.82,3.82,0,0,1,4.16-6.41,40.79,40.79,0,0,0,6.4,3.42,40,40,0,0,0,6.62,2.16,41.07,41.07,0,0,0,21.12-.63,49.92,49.92,0,0,0,19.73-11.48,45.64,45.64,0,0,0,12.56-19.61,44.86,44.86,0,0,0,1.1-23.11,52.48,52.48,0,0,0-3-9.67A45.1,45.1,0,0,0,59.43,8.73a52.47,52.47,0,0,0-9.68,3.09A45,45,0,0,0,29.14,29.59a47,47,0,0,0-7,26l8.75-9.72A3.83,3.83,0,1,1,36.61,51L21.89,67.37a3.83,3.83,0,0,1-5.4.28L1.17,52.86a3.82,3.82,0,0,1,5.3-5.51l8.05,7.77ZM62.71,32.89a3.83,3.83,0,1,1,7.66,0v21.9l17.27,9.59a3.82,3.82,0,0,1-3.71,6.67L65,60.52A3.83,3.83,0,0,1,62.71,57V32.89Z"
        />
    </svg>
);

// function getOrdinalIndicator(number) {
//     const suffixes = ["th", "st", "nd", "rd"];
//     const lastDigit = number % 10;
//     const lastTwoDigits = number % 100;

//     // Special case: 11, 12, and 13 use 'th' regardless of their last digit
//     if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
//         return number + "th";
//     }

//     // For all other numbers, use the appropriate suffix
//     const suffix = suffixes[lastDigit] || "th";
//     return number + suffix;
// }

class DragDropAppointmentList extends Component {
    constructor(props) {
        super(props);
        const layout = this.generateLayout();
        this.state = {
            layout,
        };
    }

    componentDidMount() {
        if (this.props.blockTimeHeight) {
            let { layout } = this.state;
            layout.rowHeight = this.props.blockTimeHeight;
            this.setState({ layout });
        }

        this.mapAppointmentToLayout();
    }

    onItemClick = (e, service) => {
        if (service.type === 3) {
            if (this.props.allowCreateBlockTime) {
                this.props.navigate(`/block-time?aid=${service.appointmentId}&date=${service.date}&start=${service.start}&end=${service.end}&notes=${service.notes}`);
            }
        } else {
            this.props.navigate(`/appointment?aid=${service.appointmentId}`);
        }
    };

    countClientDubPhone = (value, checkAppointments) => {
        let countDup = 0;
        for (let appointment of checkAppointments) {
            if (appointment.phone === value) {
                countDup++;
            }
        }
        return countDup;
    };

    isEarlyCheckedInAppointment = (appointment) => {
        if (!appointment.checkedInAt) {
            return false
        }
        let appointmentTime = dayjs(`${appointment.date} ${appointment.services[0].start}`, 'YYYY-MM-DD hh:mm')
        let checkedInTime = dayjs(appointment.checkedInAt)
        if (appointment.type === 1 && appointment.status === "Checked-in" && appointment.checkedInByClient && appointmentTime.diff(checkedInTime, 'minute') > 15) {
            return true
        }
        return false
    }

    generateColorMapForService = () => {
        const colors = {};
        const { menu } = this.props;

        menu.forEach((service) => {
            colors[service._id] = service.color;
        });
        return colors
    };


    checkLayoutItemOverlapped = (newLayout) => {
        let overlapGroups = [];
        let overlapItems = new Set();
        let newOverlapFound = true;
        while (newOverlapFound) {
            newOverlapFound = false;

            for (let i = 0; i < newLayout.length; i++) {
                for (let j = i + 1; j < newLayout.length; j++) {
                    // Check if items have the same x and overlap in y
                    if (Math.floor(newLayout[i].x) === Math.floor(newLayout[j].x) &&
                        newLayout[i].y < newLayout[j].y + newLayout[j].h &&
                        newLayout[i].y + newLayout[i].h > newLayout[j].y) {
                        // If they do, find or create their overlap group
                        let group = overlapGroups.find(group => group.includes(i) || group.includes(j));
                        if (!group) {
                            group = [];
                            overlapGroups.push(group);
                        }
                        if (!group.includes(i)) {
                            group.push(i);
                            newOverlapFound = true;
                        }
                        if (!group.includes(j)) {
                            group.push(j);
                            newOverlapFound = true;
                        }

                        overlapItems.add(i);
                        overlapItems.add(j);
                    }
                }
            }
        }


        overlapGroups.forEach(group => {
            const newWidth = 1 / group.length;
            group.forEach(i => {
                newLayout[i].w = newWidth;
                newLayout[i].x = Math.floor(newLayout[i].x) + newWidth * group.indexOf(i);
            });

        });

        // Set the width of items that don't overlap with any other items to 1
        newLayout.forEach((item, i) => {
            if (!overlapItems.has(i)) {
                item.w = 1;
                newLayout[i].x = Math.floor(newLayout[i].x)
            }
        });


        return newLayout
    }

    mapAppointmentToLayout = () => {
        const {
            appointments,
            staffInfo
        } = this.props;

        if (appointments.length === 0 || !staffInfo) {
            return []
        }

        const colors = this.generateColorMapForService();

        let checkDupNameAppointments = [];
        const services = appointments.map((appointment) => {
            checkDupNameAppointments.push(appointment);
            const formattedService = appointment.services.map(
                (service, index) => {
                    for (const [key] of Object.entries(appointment)) {
                        if (!["_id", "services"].includes(key)) {
                            service[key] = appointment[key];
                        }

                        service.clientName =
                            appointment.clientName === "Unknown"
                                ? appointment.phone
                                    ? "Unknown"
                                    : appointment.clientName
                                : appointment.clientName;
                        service.countDupPhone = this.countClientDubPhone(
                            appointment.phone,
                            checkDupNameAppointments
                        );
                        service.countService = appointment.services.length;
                        service.appointmentId = appointment._id;
                        service.color = colors[service._id] || "#d9d9d9";
                        service.index = index;
                        service.type = appointment.type;
                        service.isEarlyCheckedIn = this.isEarlyCheckedInAppointment(appointment)
                    }
                    return service;
                }
            );
            return formattedService;
        });

        //change to real staff id later
        let serviceList = services.flat().filter((service) => {
            return service.staff === staffInfo._id;
        });



        var layout = serviceList.map((service) => {
            return {
                x: 0,
                y:
                    dayjs(service.start, "hh:mm").diff(
                        dayjs("06:00", "hh:mm"),
                        "minutes"
                    ) / 15,

                w: 1,
                h: service.duration / 15,
                i: service.appointmentId + "." + service.index,
                service: service,
            };
        });

        //Check if layout items overlapped
        layout = this.checkLayoutItemOverlapped(layout)

        this.setState({ layout });
    };

    generateLayout() {
        const items = [{ i: "0", x: 0, y: 0, w: 1, h: 1 }];
        return items;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.appointments !== this.props.appointments) {
            this.mapAppointmentToLayout();
        }
    }

    displayServiceBackgroundColor = (service) => {
        if (service.status === "Completed") {
            return "#d9d9d9";
        }
        if (service.color) {
            return service.color;
        }
        return "#d9d9d9";
    };

    displayAppointmentService = (service) => {

        return (
            <Row
                style={{ zIndex: 10, }}
                justify='space-between'
                align='center'
            // onClick={(e) => {
            //     this.onItemClick(e, service);
            // }}
            >
                <Col style={{ width: "100%", lineHeight: "16px" }}>

                    <span
                        className='noselect'
                    // onClick={(e) => {
                    //     this.onItemClick(e, service);
                    // }}
                    // onTouchStart={(e) => {
                    //     this.onItemClick(e, service);
                    // }}

                    >
                        {service.preferredStaff && (
                            <Tooltip title='Client has a preferred staff'>
                                <HeartIcon />
                            </Tooltip>
                        )}
                        <span className='noselect'>
                            {" "}
                            {service.name.split(" ").slice(0, 5).join(" ")}{" "}
                            {service.name.split(" ").length > 5 && "... "}
                        </span>
                        {" "}
                        - {" "}

                        <span className='cancelDragAndDrop'>
                            {decodeHtmlEntities(service.clientName).split(" ").slice(0, 3).join(" ")} {" "}
                        </span>

                        <span style={{ fontSize: "11px" }}>
                            {dayjs(service.start, "HH:mm").format("hh:mm")} -{" "}
                            {dayjs(service.end, "HH:mm").format("hh:mm")} {" "}
                        </span>


                    </span>
                    <AppointmentStatus
                        status={service.status}
                        noMargin
                        displayText={false}
                    />


                    {service.notes && (
                        <Tooltip title='Client left a note'>
                            <MessageIcon />
                        </Tooltip>
                    )}{" "}
                    {(service.isEarlyCheckedIn) && (
                        <Tooltip title='Early checked in'>
                            <Icon component={EarlySvg} style={{ color: "red" }} />
                        </Tooltip>
                    )}{" "}




                </Col>
            </Row>
        );
    };

    displayAppointmentBlockTime = (service) => {
        return (
            <div
                style={{ zIndex: 10 }}
            // onClick={(e) => {
            //     this.onItemClick(e, service);
            // }}
            // onTouchStart={(e) => {
            //     this.onItemClick(e, service);
            // }}
            >
                <span
                    className='noselect'
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                    Blocked Time
                </span>
                {service.notes && <MessageIcon />}
                <br />
                <span style={{ fontSize: "11px" }}>
                    {dayjs(service.start, "HH:mm").format("hh:mm")} -{" "}
                    {dayjs(service.end, "HH:mm").format("hh:mm")}
                </span>
                <br />
                <span style={{ fontSize: "11px" }}>
                    {decodeHtmlEntities(service.notes)}
                </span>
            </div>
        );
    };

    generateDOM() {

        return this.state.layout.map((item) => {
            const { service } = item;
            if (!service) {
                return null;
            }

            return (
                <div
                    key={item.i}
                    // className={`appointment-item-drag-drop noselect `}
                    style={{
                        backgroundColor:
                            this.displayServiceBackgroundColor(service),
                        zIndex: 4,
                        borderRadius: "5px",
                        padding: "4px",
                        cursor: "pointer",
                        overflow: "hidden",
                        borderBottom: "1px solid #e8e8e8",
                    }}
                    onClick={(e) => {
                        this.onItemClick(e, service);
                    }}
                >

                    {service.type === 3
                        ? this.displayAppointmentBlockTime(service)
                        : this.displayAppointmentService(
                            service,
                        )}

                </div>
            );
        });
    }

    render() {
        return (
            <div>
                <ReactGridLayout
                    layout={this.state.layout}
                    style={{ marginLeft: 40, height: `${this.props.blockTimeHeight * 18 * 4}px` }}
                    rowHeight={this.props.blockTimeHeight}
                    cols={1}
                    className='layout'
                    compactType={null}
                    preventCollision={true}
                    margin={[1, 0]}
                    isDraggable={false}
                    isResizable={false}

                >
                    {this.generateDOM()}
                </ReactGridLayout>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    appointments: state.appointment.appointments,
    menu: state.generalInfo.menu,
    staffInfo: state.generalInfo.staff,
});

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DragDropAppointmentList);
