import React from 'react'
import { Dropdown, Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"

const AddNew = (props) => {

    const items = [
        {
            label: <Button
                onClick={() => {
                    props.handleChangeWaitingBlockTime(true)
                }}
                type="link" style={{ color: "#178fff" }}
                size="large"
            >
                New Block Time
            </Button>,

            key: '1',
        },
    ]


    return (

        <Dropdown placement="topRight" menu={{ items }} trigger="click" >
            <Button
                style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    position: "fixed",
                    bottom: "70px",
                    right: "20px",
                    zIndex: 100,
                }}
                icon={<span style={{ fontSize: "24px" }}> <PlusOutlined /></span>}
                type="primary" />
        </Dropdown>

    )
}

export default AddNew
