import React, { useEffect, useState } from 'react'
import { ActionSheet, NavBar, TabBar } from 'antd-mobile'
import {
    useLocation,
    useRoutes,
    useNavigate,
} from 'react-router-dom'
import { CalendarOutlined, HistoryOutlined, PushpinOutlined, UserOutlined } from '@ant-design/icons'
import '../../index.css'
// import { CheckOutline } from 'antd-mobile-icons'
import Appointments from '../../pages/Appointments/Appointments'
import enUS from 'antd-mobile/es/locales/en-US'
import { ConfigProvider } from 'antd-mobile'
import { getGeneralInfo } from '../../redux/actions/generalInfo/generalInfo'
import { useDispatch, useSelector } from 'react-redux'
import { MessageError } from '../../component/Common/MessageError/MessageError'
import Loading from '../../component/Common/Loading/Loading'
import AppointmentDetail from '../../pages/AppointmentDetail/AppointmentDetail'
import CalendarAppointments from '../../pages/CalendarAppointments/CalendarAppointments'
import Sales from '../../pages/History/Sales/Sales'
import dayjs from 'dayjs'
import Tips from '../../pages/History/Tips/Tips'
import queryString from 'query-string'
import Profile from '../../pages/Profile/Profile'
import Error404 from '../../pages/Error/Error404'
import { logout } from '../../redux/actions/authenticate/authenticate'
import ChangePassword from '../../pages/ChangePassword/ChangePassword'
import BlockTime from '../../pages/BlockTime/BlockTime'

const MenuContainer = () => {

    const dispatch = useDispatch()
    const { error, message, loading, staff } = useSelector(state => state.generalInfo)
    const { user } = useSelector(state => state.authenticate)
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location
    const [historyVisible, setHistoryVisible] = useState(false)
    const [accountVisible, setAccountVisible] = useState(false)
    const [historyFromTo, setHistoryFromTo] = useState({ from: dayjs().format('YYYY-MM-DD'), to: dayjs().format('YYYY-MM-DD') })
    const { authenticated, isRequiredChangePassword, permissions } = user
    const hasHistory = permissions.includes("Sales") || permissions.includes("Tips")

    useEffect(() => {
        const { historyFrom, historyTo } = queryString.parse(location.search)
        if (historyFrom && historyTo) {
            setHistoryFromTo({ from: historyFrom, to: historyTo })
        }
    }, [location.search])

    useEffect(() => {
        if (authenticated) {
            navigate(isRequiredChangePassword ? "/account/change-password" : "/")
        }
    }, [authenticated, isRequiredChangePassword, navigate])

    const setRouteActive = (value) => {
        const routerWithChilds = ["/history", "/account"]
        if (routerWithChilds.includes(value)) {
            if (value === "/history") {
                setHistoryVisible(true)
            } else if (value === "/account") {
                setAccountVisible(true)
            }
        }
        else {
            navigate(value)
        }
    }

    const elements = [
        {
            path: "/",
            element: <Appointments />,
            icon: <PushpinOutlined style={pathname === "/" ? { color: "#1677ff", fontWeight: "bold" } : null} />,
            title: "Appointments",
        },
        {
            path: "/calendar",
            element: <CalendarAppointments />,
            icon: <CalendarOutlined style={pathname === "/calendar" ? { color: "#1677ff", fontWeight: "bold" } : null} />,
            title: "Calendar",
        },
        hasHistory &&
        ({
            path: "/history",
            icon: <HistoryOutlined style={pathname.includes("/history") ? { color: "#1677ff", fontWeight: "bold" } : null} />,
            title: "History",
            children: [
                {
                    text: <div style={pathname === `/history/sales` ? { color: "#1677ff", fontWeight: "bold" } : null}>
                        Sales {pathname === "/history/sales"}
                    </div>,
                    key: "Sales",
                    onClick: () => {
                        navigate(`/history/sales?historyFrom=${historyFromTo.from}&historyTo=${historyFromTo.to}`)
                        setHistoryVisible(false)
                    },
                    path: "sales",
                    element: <Sales historyFromTo={historyFromTo} />,
                    disabled: !permissions.includes("Sales")
                },

                {
                    text: <div style={pathname === `/history/tips` ? { color: "#1677ff", fontWeight: "bold" } : null}>
                        Tips {pathname === "/history/tips"}
                    </div>,
                    key: "Tips",
                    onClick: () => {
                        navigate(`/history/tips?historyFrom=${historyFromTo.from}&historyTo=${historyFromTo.to}`)
                        setHistoryVisible(false)
                    },
                    path: "tips",
                    element: <Tips historyFromTo={historyFromTo} />,
                    disabled: !permissions.includes("Tips")
                }
            ]
        }),
        {
            path: "/account",
            icon: <UserOutlined style={pathname.includes("/account") ? { color: "#1677ff", fontWeight: "bold" } : null} />,
            title: "Account",
            children: [
                {
                    text: <div style={pathname === "/account/profile" ? { color: "#1677ff", fontWeight: "bold" } : null}>
                        Profile {pathname === "/account/profile"}
                    </div>,
                    key: "Profile",
                    onClick: () => {
                        navigate("/account/profile")
                        setAccountVisible(false)
                    },
                    path: "profile",
                    element: <Profile />,
                    disabled: isRequiredChangePassword
                },
                {
                    text: <div style={pathname === "/account/change-password" ? { color: "#1677ff", fontWeight: "bold" } : null}>
                        Change password {pathname === "/account/change-password"}
                    </div>,
                    key: "ChangePassword",
                    onClick: () => {
                        navigate("/account/change-password")
                        setAccountVisible(false)
                    },
                    path: "change-password",
                    element: <ChangePassword />,
                },
                {
                    text: "Logout",
                    key: "Logout",
                    danger: true,
                    onClick: () => dispatch(logout())
                }
            ]
        },
        {
            path: "/appointment",
            element: <AppointmentDetail />,
            noNavigation: true
        },
        {
            path: "/block-time",
            element: <BlockTime />,
            noNavigation: true
        },
        {
            path: "*",
            element: <Error404 />,
            noNavigation: true
        },
    ];

    const routes = useRoutes(elements)

    useEffect(() => {
        dispatch(getGeneralInfo())
    }, [dispatch])

    useEffect(() => {
        MessageError(message, error)
    }, [error, message])

    if (loading) {
        return <Loading />
    }


    return (
        <ConfigProvider locale={enUS}>
            <div
                className="layout"
                style={{ minHeight: window.innerHeight }}
            >
                <div className="top-nav"  >
                    <NavBar back={null}>{staff?.name}</NavBar>
                </div>
                <div className="contain">
                    {routes}
                </div>
                <div className="bottom-nav"  >
                    <TabBar
                        activeKey={null}
                        onChange={value => setRouteActive(value)}
                        safeArea={true}
                    >
                        {elements.map(item => (
                            item.noNavigation
                                ? null
                                : <TabBar.Item
                                    key={item.path}
                                    icon={item.icon}
                                    title={item.title}
                                    style={
                                        isRequiredChangePassword && item.title !== "Account"
                                            ? { opacity: 0.3, pointerEvents: "none" }
                                            : {}
                                    }
                                />
                        ))}
                    </TabBar>
                </div>
                {hasHistory && <ActionSheet
                    visible={historyVisible}
                    actions={elements.find(item => item.path === "/history").children.filter(item => item.disabled === false)}
                    onClose={() => setHistoryVisible(false)}
                />}
                <ActionSheet
                    visible={accountVisible}
                    actions={elements.find(item => item.path === "/account").children}
                    onClose={() => setAccountVisible(false)}
                />
            </div>
        </ConfigProvider>
    )
}

export default MenuContainer;