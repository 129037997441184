import { Button, DatePicker, Dialog, Form, NavBar, Picker, TextArea } from 'antd-mobile'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import queryString from 'query-string'
import { MessageError } from '../../component/Common/MessageError/MessageError'
import Loading from '../../component/Common/Loading/Loading'
import dayjs from 'dayjs'
import { SaveOutlined } from '@ant-design/icons'
import { hoursOfDay } from '../../utils/utility'
import { createBlockTime, getAppointments, removeBlockTime, updateBlockTime } from '../../redux/actions/appointment/appointment'
import { DeleteOutline, ExclamationCircleFill } from 'antd-mobile-icons'

const BlockTime = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [form] = Form.useForm()
    const { error, message, loading, appointments } = useSelector(state => state.appointment)
    const { staff } = useSelector(state => state.generalInfo)
    const [filteredEndTimes, setFilteredEndTimes] = useState([]);
    const [availableHours, setAvailableHours] = useState([]);
    let queryObject = queryString.parse(location.search)
    const { date, createNew, aid, start, end, notes } = queryObject

    useEffect(() => {
        const business = localStorage.getItem("business") ? JSON.parse(localStorage.getItem("business")) : null
        if (business) {
            dispatch(getAppointments(date, business.timeZone))
        }
    }, [date, createNew, aid, dispatch])

    useEffect(() => {
        MessageError(message, error)
        if (message) {
            navigate('/')
        }
    }, [error, message, navigate])

    useEffect(() => {
        const hours = hoursOfDay();

        const filterEndTimes = (startTime) => {
            let queryObject = queryString.parse(location.search, { parseBooleans: true });
            const { createNew, start, end } = queryObject;
            let smallestStart = "23:45";
            var staffBlockTimes = appointments.flatMap(appointment =>
                appointment.services.map(service => ({
                    ...service,
                }))
            );

            if (!createNew) {
                staffBlockTimes = staffBlockTimes.filter(block => block.end !== end && block.start !== start);
            }
            staffBlockTimes.forEach(block => {
                const { start } = block;
                if ((smallestStart >= start) && (start >= startTime)) {
                    smallestStart = start;
                }
            });

            const endAvailableTimes = hours.filter(hour => (smallestStart >= hour.value) && (hour.value > startTime));
            return endAvailableTimes;
        }

        const filterHours = (except = null) => {
            let availableStarts = [];
            let staffBlockTimes = appointments.flatMap(appointment =>
                appointment.services.map(service => ({
                    ...service,
                }))
            );

            if (except) {
                staffBlockTimes = staffBlockTimes.filter(block => block.start !== except.start && block.end !== except.end);
            }

            for (let i = 0; i < hours.length; i++) {
                const hour = hours[i].value;
                let isBlocked = false;

                for (let j = 0; j < staffBlockTimes.length; j++) {
                    const { start, end } = staffBlockTimes[j];

                    if (hour >= start && hour < end) {
                        isBlocked = true;
                        break;
                    }
                }

                if (!isBlocked) {
                    availableStarts.push(hours[i]);
                }
            }

            return availableStarts;
        }
        if (createNew) {
            const availableStart = filterHours();
            const filteredEnd = filterEndTimes(start);
            setAvailableHours(availableStart);
            setFilteredEndTimes(filteredEnd);
        } else {
            const availableStart = filterHours({ start, end });
            const filteredEnd = filterEndTimes(start);
            setAvailableHours(availableStart);
            setFilteredEndTimes(filteredEnd);
        }
    }, [appointments, location.search, createNew, start, end]);

    const handleSubmit = (values) => {
        const formValues = form.getFieldsValue()

        const blockTime = {
            _id: aid,
            date: dayjs(formValues.date).format('YYYY-MM-DD'),
            services: [
                {
                    name: "Block time",
                    start: formValues.startTime[0],
                    end: formValues.endTime[0],
                    duration: dayjs(formValues.endTime[0], 'HH:mm').diff(dayjs(formValues.startTime[0], 'HH:mm'), 'minute'),
                    staff: staff._id,
                    staffName: staff.name,
                }
            ],
            type: 3,
            notes: formValues.description,
        }

        createNew ? dispatch(createBlockTime(blockTime)) : dispatch(updateBlockTime(blockTime))
    }

    const handleConfirmDelete = () => {
        dispatch(removeBlockTime(aid))
    }

    const onStartTimeChange = (value) => {
        const hours = hoursOfDay();

        const filterEndTimes = (startTime) => {
            let queryObject = queryString.parse(location.search, { parseBooleans: true });
            const { createNew, start, end } = queryObject;
            let smallestStart = "23:45";
            var staffBlockTimes = appointments.flatMap(appointment =>
                appointment.services.map(service => ({
                    ...service,
                }))
            );

            if (!createNew) {
                staffBlockTimes = staffBlockTimes.filter(block => block.end !== end && block.start !== start);
            }
            staffBlockTimes.forEach(block => {
                const { start } = block;
                if ((smallestStart >= start) && (start >= startTime)) {
                    smallestStart = start;
                }
            });

            const endAvailableTimes = hours.filter(hour => (smallestStart >= hour.value) && (hour.value > startTime));
            return endAvailableTimes;
        }

        const endTime = form.getFieldValue("endTime") ? form.getFieldValue("endTime")[0] : null;
        if (dayjs(value, "HH:mm").isSameOrAfter(dayjs(endTime, "HH:mm"))) {
            form.setFieldsValue({ endTime: [dayjs(value, 'HH:mm').add(15, 'minutes').format("HH:mm")] });
        }

        const newFilteredEndTimes = filterEndTimes(value);

        const currentEndTime = form.getFieldValue("endTime")[0];

        if (!newFilteredEndTimes.find(hour => hour.value === currentEndTime)) {
            form?.setFieldsValue({ endTime: [] });
        }

        setFilteredEndTimes(newFilteredEndTimes);
    };

    const handleChangeDate = (value) => {
        queryObject = { ...queryObject, date: dayjs(value).format('YYYY-MM-DD') };
        const searchString = queryString.stringify(queryObject);
        navigate({ path: "/block-time", search: searchString });
    };


    if (loading) {
        return <Loading />
    }

    return (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 100, background: "white" }}>
            <div className="top-nav"  >
                <NavBar onBack={() => navigate(-1)}>{`${createNew ? 'Add' : 'Edit'} Block Time`}</NavBar>
            </div>
            <div style={{ padding: "20px 0" }}>

                {!createNew &&
                    <div style={{ textAlign: 'right', margin: '0px 10px 10px 0px' }}>
                        <Button
                            color='danger'
                            fill='none'
                            size='small'
                            onClick={() => {
                                Dialog.show({
                                    content: 'Are you sure you want to delete this block time?',
                                    closeOnAction: true,
                                    header: (<div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <ExclamationCircleFill
                                            style={{
                                                fontSize: '20px',
                                                color: 'var(--adm-color-warning)',
                                            }}
                                        />
                                        <span style={{ marginLeft: '5px', fontSize: '18px' }}>Confirm Delete</span>
                                    </div>

                                    ),
                                    actions: [
                                        [
                                            {
                                                key: 'cancel',
                                                text: 'Cancel',
                                            },
                                            {
                                                key: 'confirm',
                                                text: 'Confirm',
                                                bold: true,
                                                danger: true,
                                                onClick: handleConfirmDelete
                                            },
                                        ],
                                    ],
                                })
                            }}
                        >
                            <DeleteOutline /> Delete
                        </Button>
                    </div>
                }

                <Form
                    form={form}
                    mode='card'
                    layout='horizontal'
                    style={{ textAlign: 'left' }}
                    footer={
                        <Button block type='submit' color='primary' size='large' shape='rounded'>
                            <SaveOutlined /> Save
                        </Button>
                    }
                    onFinish={(values) => handleSubmit(values)}
                    initialValues={{
                        date: new Date(dayjs(date)),
                        startTime: [start],
                        endTime: end
                            ? [end]
                            : [dayjs(start, 'HH:mm').add(30, 'minute').format('HH:mm')],
                        description: notes
                    }}
                >

                    <Form.Item
                        name='date'
                        label='Date'
                        trigger='onConfirm'
                        onClick={(e, datePickerRef) => {
                            datePickerRef.current?.open()
                        }}
                        rules={[{ required: true, message: 'Please select a date' }]}
                    >
                        <DatePicker
                            onConfirm={handleChangeDate}
                            min={new Date(dayjs())}
                            max={new Date(dayjs().add(1, 'month'))}
                        >
                            {value =>
                                value ? dayjs(value).format('YYYY-MM-DD') : <span style={{ color: "#cbcbcb" }}>select a date</span>
                            }
                        </DatePicker>
                    </Form.Item>

                    <Form.Item
                        name='startTime'
                        label='Start time'
                        trigger='onConfirm'
                        onClick={(e, picker) => {
                            picker.current?.open()
                        }}
                        rules={[{ required: true, message: 'Please select start time' }]}
                    >
                        <Picker
                            columns={[availableHours]}
                            onConfirm={onStartTimeChange}
                        >
                            {value => {
                                return (value && value[0]) ? value[0]?.label : <span style={{ color: "#cbcbcb" }}>select start time</span>
                            }}
                        </Picker>
                    </Form.Item>

                    <Form.Item
                        name='endTime'
                        label='End time'
                        trigger='onConfirm'
                        onClick={(e, picker) => {
                            picker.current?.open()
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Please select end time',

                            },
                            {
                                validator: (rule, value) => {
                                    if (value && form.getFieldValue('startTime') && value[0] <= form.getFieldValue('startTime')[0]) {
                                        return Promise.reject('End time must be after start time')
                                    }
                                    return Promise.resolve()
                                }
                            }]}
                    >
                        <Picker
                            columns={[filteredEndTimes]}
                        >
                            {value => {
                                return value && value[0] ? value[0]?.label : <span style={{ color: "#cbcbcb" }}>select end time</span>
                            }}
                        </Picker>
                    </Form.Item>

                    <Form.Item name='description' label='Description (Optional)' >
                        <TextArea
                            placeholder='e.g break'
                            rows={3}
                        />
                    </Form.Item>
                </Form>

            </div>
        </div>
    )
}

export default BlockTime